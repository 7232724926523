import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyCustomerModule } from './extensions/21e18144b7e8f979ebe4dedf9c556ff710b04af58361dda7218ff1c56719c205/verify-customer.module';
import { OrderExportNavModule } from './extensions/6715e507a651bb68cf204334877cbd8b2fd0fb203b155404986b53f504088778/order-export-nav.module';
import { LookBookUiExtensionModule } from './extensions/184057b282c05a5a01fcfbd2c4ea4187488dddb5cbee0cde624c3e464fab63a0/lookbook-ui-extension.module';
import { ProductsUiExtensionModule } from './extensions/f47d4bd479c7962cdc85cbbc59fc57b01d66d1a4e5d549ba90959152904b68a2/products-ui-extension.module';
import { ReviewsUiExtensionModule } from './extensions/2932d9a3e622eb10b901caceffc4291a31245cb4c27cf77896cca18d589b8a4e/reviews-ui-extension.module';
import { QuestionsUiExtensionModule } from './extensions/b8a0e0ca770a7966ad3c6040a49067b711e1d8da29092f14d035da48b3b3ec4a/questions-ui-extension.module';
import { MetricsWidgetSharedModule } from './extensions/e9d310dbc26bd6be14b8673634213809ba9c420292f8d3723e476571d0708553/metrics-widget.shared-module';
import { CustomCustomerHistoryEntryModule } from './extensions/36dcd8c8aa38566bcc975035f6db4ed99e07ec6599acae20589f736f920f06f7/custom-ui-customer-history-entry.module';
import { PayoutNavModule } from './extensions/337552048ee9af345974cbe1ec7ab2993804127cc18fed3d877ce9f4c7989f8f/payout-nav.module';


@NgModule({
    imports: [CommonModule, VerifyCustomerModule, OrderExportNavModule, LookBookUiExtensionModule, ProductsUiExtensionModule, ReviewsUiExtensionModule, QuestionsUiExtensionModule, MetricsWidgetSharedModule, CustomCustomerHistoryEntryModule, PayoutNavModule],
})
export class SharedExtensionsModule {}

import { Component, NgModule } from '@angular/core';
import {
  CustomerFragment,
  CustomerHistoryEntryComponent,
  registerHistoryEntryComponent,
  SharedModule,
  TimelineDisplayType,
  TimelineHistoryEntry,
} from '@vendure/admin-ui/core';

@Component({
  selector: 'customer-login-history-entry-component',
  template: `
    <div *ngIf="entry.data">
      Customer logged in via {{ entry.data.device }}
      <vdr-history-entry-detail *ngIf="entry.data">
        <vdr-object-tree [value]="entry.data"></vdr-object-tree>
      </vdr-history-entry-detail>
    </div>
  `,
})
class CustomerLoginHistoryEntryComponent implements CustomerHistoryEntryComponent {
  entry: TimelineHistoryEntry;
  customer: CustomerFragment;

  getDisplayType(entry: TimelineHistoryEntry): TimelineDisplayType {
    return 'success'
  }

  getName(entry: TimelineHistoryEntry): string {
    return this.customer.firstName + " " + this.customer.lastName
  }

  isFeatured(entry: TimelineHistoryEntry): boolean {
    return true;
  }

  getIconShape(entry: TimelineHistoryEntry) {
    return 'user'
  }
}

@NgModule({
  imports: [SharedModule],
  declarations: [CustomerLoginHistoryEntryComponent],
  providers: [
    registerHistoryEntryComponent({
      type: 'CUSTOMER_LOGIN', 
      component:CustomerLoginHistoryEntryComponent,
    }),
  ]
})
export class CustomCustomerHistoryEntryModule {}
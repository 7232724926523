import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'facets-list-select',
  templateUrl: './list-selection.component.html',
  styleUrls: ['./list-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})


export class ListSelectionComponent implements OnInit {
  
  @Input() children: any;
  @Input() inputName: any;
  
  
  constructor() {
  }
  
  ngOnInit() {
  
  }
  
}


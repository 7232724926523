import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldControl, DataService } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { GET_QUESTIONS_FOR_PRODUCT } from '../product-questions-list/product-questions-list.graphql';
import { GetQuestionForProduct, ProductQuestionFragment } from '../../generated-types';

@Component({
    selector: 'kb-relation-question-input',
    template: `
        <select appendTo="body" [formControl]="formControl">
            <option [ngValue]="null">Select a question...</option>
            <option *ngFor="let item of questions$ | async" [ngValue]="item">
                <b>{{ item.summary }}</b>
            </option>
        </select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationQuestionInputComponent implements OnInit, CustomFieldControl {
    @Input() readonly: boolean;
    @Input() formControl: FormControl;
    @Input() config: any;

    questions$: Observable<ProductQuestionFragment[]>;

    constructor(private dataService: DataService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.questions$ = this.route.data.pipe(
            switchMap(data => data.entity),
            switchMap((product: any) => {
                return this.dataService
                    .query<GetQuestionForProduct.Query, GetQuestionForProduct.Variables>(
                        GET_QUESTIONS_FOR_PRODUCT,
                        {
                            productId: product.id,
                        },
                    )
                    .mapSingle(({ product }) => product?.questions.items ?? []);
            }),
        );
    }
}

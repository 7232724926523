import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  BaseDetailComponent,
  CustomDetailComponent,
  CustomFieldConfig,
  CustomFieldControl,
  DataService,
  GetProductWithVariants,
  ProductDetail,
  ServerConfigService,
} from '@vendure/admin-ui/core';

import { GetCollection, GetFacetList } from "../../types";
import { GET_COLLECTION, GET_FACETS } from "../../ui.graphql";
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FacetDefaultProductUsed } from "../../entities";


@Component({
  templateUrl: './brands-list-selection.component.html',
  styleUrls: ['./brands-list-selection.component.css'],
})


export class BrandsListSelectionComponent extends BaseDetailComponent<ProductDetail.Fragment> implements OnInit, CustomDetailComponent,
    CustomFieldControl {
  
  //isListInput = true;
  detailForm: FormGroup;
  formBuilder: FormBuilder;
  formControl: FormControl;
  entity$: Observable<any>;
  readonly: boolean;
  config: any;
  collection$: Observable<any>;
  product$: Observable<GetProductWithVariants.Product>;
  facets$: Observable<GetFacetList.Items[]>;
  id: string;
  customFields: CustomFieldConfig[];
  selectedItems = [];
  collectionArr: any = [];
  protected destroy$ = new Subject<void>();
  
  constructor(
      route: ActivatedRoute,
      router: Router,
      serverConfigService: ServerConfigService,
      protected dataService: DataService
  ) {
    super(route, router, serverConfigService, dataService);
    
  }
  
  ngOnInit() {
    
    this.collection$ = this.dataService
        .query<GetCollection.Query, GetCollection.Variables>(GET_COLLECTION, {
          slug: 'brands',
        }).mapSingle(data => data.collection);
    
    this.entity$ = this.route.data.pipe(
        switchMap(data => (data.entity as Observable<any>).pipe(takeUntil(this.destroy$))),
        tap(entity => (this.id = entity.id)),
        shareReplay(1),
    );
    
    this.facets$ = this.dataService
        .query<GetFacetList.Query, GetFacetList.Variables>(GET_FACETS, {
          options: {
            filter: {
              code: {
                eq: FacetDefaultProductUsed.brands
              }
            }
          },
        }).mapStream(data => data.facets.items);
    
    this.product$ = this.entity$;
    
    //this.init();
    
    
  }
  
  init() {
    this.detailForm = new FormGroup({
      brands: this.createCollectionBrands(this.collectionArr)
    });
  }
  
  createCollectionBrands(brandCollection: any) {
    const arr = brandCollection.map((param: any) => {
      return new FormControl(param.selected || false);
    });
    
    return new FormArray(arr);
  }
  
  onSelectBrand(item: any, event: any) {
    if (event.target.checked) {
      // @ts-ignore
      this.selectedItems.push(item.id);
    } else {
      this.selectedItems = this.selectedItems.filter(_item => _item !== item);
    }
    
    this.detailForm.get('detailForm');
    if (this.selectedItems.length > 0) {
      this.formControl.setValue(this.selectedItems);
    }
    
  }
  
  /**
   * Sets the values of the form on changes to the product or current language.
   */
  protected setFormValues(product: ProductDetail.Fragment) {
    this.detailForm.patchValue({
      brandsIds: this.collectionArr
    });
  }
}


import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomFieldControl, DataService } from '@vendure/admin-ui/core';
import { GetFacetList, } from "../../types";
import { GET_FACETS } from "../../ui.graphql";
import { FacetDefaultProductUsed } from "../../entities";


@Component({
  templateUrl: './facets-list-types-selection.component.html',
  styleUrls: ['./facets-list-types-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})


export class FacetsListTypesSelectionComponent implements OnInit, CustomFieldControl {
  
  detailForm: FormGroup;
  formControl: FormControl;
  readonly: boolean;
  config: any;
  facets$: Observable<GetFacetList.Items[]>;
  
  constructor(private dataService: DataService) {
  }
  
  ngOnInit() {
    this.facets$ = this.dataService
        .query<GetFacetList.Query, GetFacetList.Variables>(GET_FACETS, {
          options: {
            filter: {
              code: {
                eq: FacetDefaultProductUsed.type
              }
            }
          },
        }).mapStream(data => data.facets.items);
    
  }
  
}


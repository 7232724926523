import { Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { DataService } from '@vendure/admin-ui/core';


@Component({
  selector: 'custom-collection-tree-node',
  templateUrl: './custom-collection-tree-node.component.html',
  styleUrls: ['./custom-collection-tree-node.component.css']
})

export class CustomCollectionTreeNodeComponent implements OnInit {
  depth = 0;
  @Input() collectionTree: any;
  @Input() expandAll = false;
  parentName: string;
  
  constructor(
      @SkipSelf() @Optional() private parent: CustomCollectionTreeNodeComponent,
      private dataService: DataService,
  ) {
    if (parent) {
      this.depth = parent.depth + 1;
    }
  }
  
  ngOnInit() {
  
  }
  
  
  trackByFn(index: number, item: any) {
    return item.id;
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomFieldControl, DataService } from '@vendure/admin-ui/core';
import { GetFacetList, } from "../../types";
import { GET_FACETS } from "../../ui.graphql";
import { FacetDefaultProductUsed } from "../../entities";


@Component({
  templateUrl: './facets-list-materials-selection.component.html',
  styleUrls: ['./facets-list-materials-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})


export class FacetsListMaterialsSelectionComponent implements OnInit, CustomFieldControl {
  
  detailForm: FormGroup;
  formControl: FormControl;
  readonly: boolean;
  config: any;
  facets$: Observable<GetFacetList.Items[]>;
  
  constructor(private dataService: DataService) {
  }
  
  ngOnInit() {
    this.facets$ = this.dataService
        .query<GetFacetList.Query, GetFacetList.Variables>(GET_FACETS, {
          options: {
            filter: {
              code: {
                eq: FacetDefaultProductUsed.material
              }
            }
          },
        }).mapStream(data => data.facets.items);
    
  }
  
  
  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.detailForm.get('checkArray') as FormArray;
    
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
}


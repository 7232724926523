export const extensionRoutes = [  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/6715e507a651bb68cf204334877cbd8b2fd0fb203b155404986b53f504088778/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/lookbooks',
    loadChildren: () => import('./extensions/184057b282c05a5a01fcfbd2c4ea4187488dddb5cbee0cde624c3e464fab63a0/lookbook-ui-lazy.module').then(m => m.LookBookUiLazyModule),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/2932d9a3e622eb10b901caceffc4291a31245cb4c27cf77896cca18d589b8a4e/reviews-ui-lazy.module').then(m => m.ReviewsUiLazyModule),
  },
  {
    path: 'extensions/product-questions',
    loadChildren: () => import('./extensions/b8a0e0ca770a7966ad3c6040a49067b711e1d8da29092f14d035da48b3b3ec4a/questions-ui-lazy.module').then(m => m.QuestionsUiLazyModule),
  },
  {
    path: 'extensions/payout',
    loadChildren: () => import('./extensions/337552048ee9af345974cbe1ec7ab2993804127cc18fed3d877ce9f4c7989f8f/payout-form.module').then(m => m.PayoutFormModule),
  }];

import { Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { arrayToTree } from "@vendure/admin-ui/catalog";


@Component({
  selector: 'custom-collection-tree',
  templateUrl: './custom-collection-tree.component.html',
  //styleUrls: ['./custom-collection-tree.component.css']
})

export class CustomCollectionTreeComponent implements OnInit, OnChanges {
  @Input() collections: any[];
  @Input() expandAll = false;
  collectionTree: any;
  
  constructor() {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if ('collections' in changes && this.collections) {
      this.collectionTree = arrayToTree(this.collections, this.collectionTree);
    }
  }
  
  ngOnInit() {
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { QuestionStateLabelComponent } from './components/question-state-label/question-state-label.component';

@NgModule({
    imports: [SharedModule],
    declarations: [QuestionStateLabelComponent],
    exports: [QuestionStateLabelComponent, SharedModule],
})
export class QuestionsSharedModule {}

import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuSection } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule],
    providers: [
        addNavMenuSection(
            {
                id: 'lookbooks',
                label: 'LookBooks',
                items: [
                    {
                        id: 'lookbooks',
                        label: 'LookBooks',
                        routerLink: ['/extensions/lookbooks'],
                        icon: 'view-cards',
                    },
                ],
            },
            'settings',
        ),
    ],
    exports: [],
})
export class LookBookUiExtensionModule {}

import gql from 'graphql-tag';

import { PRODUCT_QUESTION_FRAGMENT } from '../../common/fragments.graphql';

export const GET_QUESTIONS_FOR_PRODUCT = gql`
    query GetQuestionForProduct($productId: ID!, $options: ProductQuestionListOptions) {
        product(id: $productId) {
            id
            questions(options: $options) {
                items {
                    ...ProductQuestion
                }
                totalItems
            }
        }
    }
    ${PRODUCT_QUESTION_FRAGMENT}
`;

export const GET_PRODUCT_PQUESTION_AND_HISTOGRAM = gql`
    query GetQuestionsHistogram($id: ID!) {
        product(id: $id) {
            id
            name
            featuredAsset {
                id
                preview
            }
            customFields {
                questionCount
            }
            questionsHistogram {
                bin
                frequency
            }
        }
    }
`;

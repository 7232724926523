import gql from 'graphql-tag';

export const GET_ALL_COLLECTIONS = gql`
    query GetCategoriesList($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                id
                name
                slug
                children {
                  id
                  name
                  slug
                }
            }
            totalItems
        }
    }
`;

export const GET_COLLECTION = gql`
    query GetCollection($id: ID, $slug: String) {
        collection(id: $id, slug: $slug) {
            id
            name
            slug
            children {
                id
                slug
                name
            }
        }
    }
`;

export const GET_FACETS = gql`
    query GetFacetList($options: FacetListOptions) {
        facets(options: $options) {
          items {
            id
            name
            code
            values {
              id
              name
              code
            }
          }
          totalItems
        }
    }
`;


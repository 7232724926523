import {NgModule} from '@angular/core';
import {addNavMenuItem, SharedModule} from '@vendure/admin-ui/core';

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'payout',
        label: 'Payout',
        routerLink: ['/extensions/payout'],
        icon: 'piggy-bank',
        requiresPermission: 'SuperAdmin',
      },
      'sales',
    ),
  ]
})
export class PayoutNavModule {}

<div
        *ngFor="let collection of collectionTree; index as i; trackBy: trackByFn"
        class="collection"

>

    <div [ngClass]="'depth-' + depth"
         class="collection-detail">

        <ng-container *ngIf="collection.children.length > 0">
            <div class="name">{{ collection.name }}</div>
            <clr-checkbox-container *ngFor="let children of collection.children">
                <clr-checkbox-wrapper>
                    <input [checked]="" [value]="children.id" clrCheckbox name="categories" type="checkbox"/>
                    <label>{{ children.name }}</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </ng-container>


    </div>

    <custom-collection-tree-node
            [collectionTree]="collection"
    ></custom-collection-tree-node>

</div>



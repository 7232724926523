<div *ngFor="let child of children"
     class="input-row">

    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input [name]="inputName" [value]="child.id" clrCheckbox type="checkbox"/>
            <label>{{ child.name }}</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>

</div>

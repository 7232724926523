import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomFieldControl, DataService, QueryResult } from '@vendure/admin-ui/core';
import { GetCollectionList } from "../../types";
import { GET_ALL_COLLECTIONS } from "../../ui.graphql";
import { CollectionDefaultUsed } from "../../entities";

@Component({
  templateUrl: './categories-type-selection.component.html',
  styleUrls: ['./categories-type-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})


export class CategoriesTypeSelectionComponent implements OnInit, CustomFieldControl {
  
  detailForm: FormGroup;
  formControl: FormControl;
  readonly: boolean;
  config: any;
  collection$: Observable<GetCollectionList.Items[]>;
  items$: Observable<GetCollectionList.Items[]>;
  data: any;
  private queryResult: QueryResult<any>;
  
  
  constructor(
      private dataService: DataService
  ) {
  
  }
  
  ngOnInit() {
    this.dataService.collection.getCollections(1000, 0).refetchOnChannelChange();
    //this.items$ = this.queryResult.mapStream(data => data.collections.items).pipe(shareReplay(1));
    
    this.collection$ = this.dataService
        .query<GetCollectionList.Query, GetCollectionList.Variables>(GET_ALL_COLLECTIONS, {
          options: {
            filter: {
              slug: {
                notEq: CollectionDefaultUsed.Brands
              }
            }
          },
        }).mapStream(data => data.collections.items);
    
  }
  
  //Click event on parent checkbox
  parentCheck(parentObj: any) {
    for (var i = 0; i < parentObj.childList.length; i++) {
      parentObj.childList[i].isSelected = parentObj.isSelected;
    }
  }
  
  //Click event on child checkbox
  childCheck(parentObj: any, childObj: any) {
    parentObj.isSelected = childObj.every(function (itemChild: any) {
      return itemChild.isSelected == true;
    });
  }
  
  //Click event on master select
  selectUnselectAll(obj: any) {
    obj.isAllSelected = !obj.isAllSelected;
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isSelected = obj.isAllSelected;
      for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
        obj.ParentChildchecklist[i].childList[j].isSelected = obj.isAllSelected;
      }
    }
  }
  
  //Expand/Collapse event on each parent
  expandCollapse(obj: any) {
    obj.isClosed = !obj.isClosed;
  }
  
  //Master expand/ collapse event
  expandCollapseAll(obj: any) {
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isClosed = !obj.isAllCollapsed;
    }
    obj.isAllCollapsed = !obj.isAllCollapsed;
  }
  
  
}


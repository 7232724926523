<!-- <div class="list-container" >-->
<!--     <pre>{{ collection$ | async  | json }}</pre>-->
<!--    <div *ngIf="collection$ | async as collection; let i=index;" class="col-md-2" >-->
<!--        <facets-list-select [children]="collection.children" [inputName]="'brands'"></facets-list-select>-->
<!--    </div>-->
<!--</div>-->

<div class="list-container">
    <div *ngIf="facets$ | async as facets" class="col-md-2">
        <ng-container *ngIf="facets.length > 0 && facets[0].values.length > 0">
            <facets-list-select [children]="facets[0].values" [inputName]="'facetValue'"></facets-list-select>
        </ng-container>
    </div>
</div>


<!--<pre>{{ product$ | async | json }}</pre>-->

export interface FacetWithValues {
  id: string;
  name: string;
  values: Array<{
    id: string;
    name: string;
    count: number;
  }>;
}


export enum FacetDefaultProductUsed {
  material = 'material',
  colour = 'colour',
  type = 'types',
  brands = 'brands',
}

export enum CollectionDefaultUsed {
  Brands = 'brands',
  Womenswear = 'womenswear',
  Menswearedit = 'menswear',
}

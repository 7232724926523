import { NgModule } from '@angular/core';
import {
    addNavMenuItem,
    registerDashboardWidget,
    registerFormInputComponent,
    setDashboardWidgetLayout,
} from '@vendure/admin-ui/core';

import { QuestionCountLinkComponent } from './components/question-count-link/question-count-link.component';
import { QuestionsSharedModule } from './questions-shared.module';
import { RelationQuestionInputComponent } from './components/featured-question-selector/featured-question-selector.component';

@NgModule({
    imports: [QuestionsSharedModule],
    declarations: [QuestionCountLinkComponent, RelationQuestionInputComponent],
    providers: [
        registerFormInputComponent('question-count-link', QuestionCountLinkComponent),
        registerFormInputComponent('question-selector-form-input', RelationQuestionInputComponent),
        addNavMenuItem(
            {
                id: 'questions',
                label: 'Product questions',
                routerLink: ['/extensions/product-questions'],
                icon: 'star',
            },
            'marketing',
        ),
        registerDashboardWidget('questions', {
            title: 'Latest questions',
            supportedWidths: [4, 6, 8, 12],
            loadComponent: () =>
                import('./widgets/questions-widget/questions-widget.component').then(
                    m => m.QuestionsWidgetComponent,
                ),
        }),
        setDashboardWidgetLayout([
            { id: 'welcome', width: 12 },
            { id: 'orderSummary', width: 6 },
            { id: 'questions', width: 6 },
            { id: 'latestOrders', width: 12 },
        ]),
    ],
    exports: [],
})
export class QuestionsUiExtensionModule {}

import {NgModule} from '@angular/core';
import {addActionBarItem, SharedModule} from '@vendure/admin-ui/core';
import gql from 'graphql-tag';

const verifyCustomerMutation = gql`
  mutation VerifyCustomer($id: ID!) {
    verifyCustomer(id: $id) {
      success
    }
  }`


@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    addActionBarItem({
      id: 'verify-customer',
      label: 'Verify',
      locationId: 'customer-detail',
      requiresPermission: 'CreateCustomer',
      buttonColor: "success",
      buttonStyle: "solid",
      icon: "check-circle",
      // Hard to make this button dynamically enabled/disabled because we cannot access the state of
      // this button and the verification status
      // disabled:false
      onClick: (_, context) => {
        const customerId = context.route.snapshot.params.id
        const result = context.dataService.mutate(verifyCustomerMutation,
          {
            id: customerId
          }
        );

        result.subscribe((data) => {
          const res = (data as {verifyCustomer:{success:boolean}}).verifyCustomer.success
          if(res){
            context.notificationService.success('Successfully verified the customer')
          } else {
            context.notificationService.error('Failed to verified the customer')
          }
        })

      },
    }),
  ],
})
export class VerifyCustomerModule {}
